<script>
import moment from "moment";

export default {
  locales: {
    pt: {
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bônus',
      'Current Balance': 'Saldo Atual',
      'Graduation': 'Graduação',
      'No Graduation': 'Sem Graduação',
      'Points': 'Pontos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    },
    es: {
      'Monthly': 'Ativação',
      'Total Bonus': 'Total de Bonus',
      'Current Balance': 'Saldo Actual',
      'Graduation': 'Graduación',
      'No Graduation': 'No Graduación',
      'Points': 'Puntos',
      'ACTIVE': 'ATIVO',
      'INACTIVE': 'INATIVO',
    }
  },
  props: {
    type: {
      type: String,
      default: "default"
    },
    title: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    icon: {
      type: String,
      default: ""
    },
    color: {
      type: String,
      default: ""
    },
    month: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      month_year: moment().format("MM/YY"),
    }
  }
};
</script>

<template>
  <div class="card" :class="color != '' ? 'bg-' + color : ' bg-white'">
    <div class="card-body p-3">
      <div v-if="type == 'line'" class="d-flex align-items-center">
        <div>
          <div class="bg-light rounded py-1 px-2">
            <i class="bx font-size-24 my-1 align-middle" :class="icon"></i>
          </div>
        </div>
        <div class="flex-fill ml-2">
          <h6 v-if="value == 'active'" class="mb-0 notranslate font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h6>
          <h6 v-else-if="value == 'inactive'" class="mb-0 notranslate font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h6>
          <h5 v-else class="mb-0 notranslate font-weight-bold d-flex justify-content-between" v-html="t(value)"></h5>
          <p class="font-weight-medium mb-0 text-uppercase font-weight-light font-size-12" v-html="t(title)"></p>
        </div>
        <div v-if="month" class="text-right">
          <div class="font-size-11 mb- bg-soft-light rounded p-1">
            {{ month_year }}
          </div>
        </div>
      </div>
      <div v-else-if="type == 'center'" class="text-center align-items-center">
        <h5 v-if="value == 'active'" class="mb-0 notranslate font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate font-weight-bold">{{ t(value) }}</h5>
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
      </div>
      <div v-else class="d-flex justify-content-between align-items-center">
        <p class="font-weight-medium mb-0 text-uppercase font-weight-light" v-html="t(title)"></p>
        <h5 v-if="value == 'active'" class="mb-0 notranslate font-weight-bold"><span class="bg-success rounded text-white px-2">{{ t('ACTIVE') }}</span></h5>
        <h5 v-else-if="value == 'inactive'" class="mb-0 notranslate font-weight-bold"><span class="bg-danger rounded text-white px-2">{{ t('INACTIVE') }}</span></h5>
        <h5 v-else class="mb-0 notranslate font-weight-bold">{{ t(value) }}</h5>
      </div>
    </div>
  </div>
</template>

<style scoped>
.bg-success {
  background: #178961 !important;
  border: solid 1px #178961 !important;
  border-bottom: solid 2px #0d6244 !important;
  border-radius: 6px;
}
.bg-success .bg-light {
  background-color: #fff !important;
}
.bg-success h5,
.bg-success p {
  color: #fff;
}

.bg-danger {
  border: solid 1px #EB1C24;
  border-bottom: solid 2px #a41419;
  border-radius: 6px;
}
.bg-danger .bg-light {
  background-color: #fff !important;
}
.bg-danger h5,
.bg-danger p {
  color: #fff;
}
</style>